import URL from '@/config/network'
import { TagTypesEnum, baseApiSlice } from '.'
import {
  AccessRequest,
  AccessRequestResponse,
  AccessStatusResponse,
  PublishedApisResponse,
  PublisherStats,
  RecentTransaction,
  RevenueResponse,
} from '@/types/publisher'

export const PublisherApi = baseApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    accessStatus: builder.query<AccessStatusResponse, void>({
      query: () => ({
        url: `${URL.publisher}access/`,
      }),
      providesTags: [TagTypesEnum.PUBLISHER_ACCESS],
    }),
    requestAccess: builder.mutation<AccessRequestResponse, AccessRequest>({
      query: (body) => ({
        url: `${URL.publisher}request-access/`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [TagTypesEnum.PUBLISHER_ACCESS],
    }),
    getPublisherStats: builder.query<PublisherStats, void>({
      query: () => ({
        url: `${URL.publisher}overall-stats/`,
      }),
    }),
    getPublishedApis: builder.query<PublishedApisResponse, void>({
      query: () => ({
        url: `${URL.publisher}`,
      }),
    }),
    getPublisherRecentTransactions: builder.query<RecentTransaction, void>({
      query: () => ({
        url: `${URL.publisher}/recent-transactions/`,
      }),
    }),
    getPublisherRevenue: builder.query<RevenueResponse, void>({
      query: () => ({
        url: `${URL.publisher}revenue-data/`,
      }),
    }),
  }),
})
