import URL from '@/config/network'
import { baseApiSlice, TagTypesEnum } from './base-api'
import {
  IApi,
  IApiEndpoint,
  IApiFaq,
  IApiPricing,
  IApiResponse,
  IApiSettings,
  MarketplaceResponse,
  MarketplaceSectionDataResponse,
} from '@/types/api'
import { AboutData, LatestFeed, Metrics } from '@/types/apiLatestFeed'
import { ApiReviewDisplayDataType, ApiReviewInput, ApiReviewResponse } from '@/types/review'

export const marketPlaceAPI = baseApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getApiList: builder.query<IApiResponse, void>({
      query: () => URL.apis,
    }),
    getApiLatestFeed: builder.query<LatestFeed, number | undefined>({
      query: (id) => `${URL.apis}/${id}/latest/`,
    }),
    getApiAboutInfo: builder.query<AboutData[], number | undefined>({
      query: (id) => `${URL.apis}/${id}/about/`,
    }),
    getApiPerformanceMetrics: builder.query<Metrics, number | undefined>({
      query: (id) => `${URL.apis}/${id}/performance`,
    }),
    getApiBySlug: builder.query<IApi, string>({
      query: (slug) => `${URL.apis}/detail?slug=${slug}`,
      providesTags: (result) => [{ type: TagTypesEnum.API_SERVICE, id: result?.id }, TagTypesEnum.API_HEADER_DETAILS],
    }),
    getApiFaq: builder.query<IApiFaq[], number | undefined>({
      query: (id) => `${URL.apis}/${id}/faqs`,
    }),
    getApiEndpoints: builder.query<IApiEndpoint[], number | undefined>({
      query: (id) => `${URL.apis}/${id}/endpoints`,
    }),
    getApiPrice: builder.query<IApiPricing[], { id?: number }>({
      query: ({ id }) => ({
        url: `${URL.apis}/${id}/pricing`,
      }),
    }),
    getApiReviews: builder.query<ApiReviewResponse, number | undefined>({
      query: (id) => ({
        url: `${URL.apis}/reviews`,
        params: {
          api_id: id,
        },
      }),
      providesTags: [{ type: TagTypesEnum.REVIEW, id: 'REVIEW' }],
    }),
    getApiReviewDisplayData: builder.query<ApiReviewDisplayDataType, number | undefined>({
      query: (id) => ({
        url: `${URL.apis}/reviews/display`,
        params: {
          api_id: id,
        },
      }),
    }),
    addReview: builder.mutation<void, ApiReviewInput>({
      query: (body) => ({
        url: `${URL.apis}reviews/`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: TagTypesEnum.REVIEW, id: 'REVIEW' }],
    }),
    getApiSettings: builder.query<IApiSettings[], number>({
      query: (orgApiId) => `/org-api/${orgApiId}/get-fields`,
      providesTags: (_, __, id) => [{ type: TagTypesEnum.API_SETTINGS, id }],
    }),
    getMarketplace: builder.query<MarketplaceResponse, void>({
      query: () => ({
        url: 'marketplace/',
      }),
    }),
    getMarketPlaceSectionData: builder.query<MarketplaceSectionDataResponse, string>({
      query: (URL) => ({
        url: URL,
      }),
    }),
    subscribeApi: builder.mutation<MarketplaceSectionDataResponse, number | undefined>({
      query: (id) => ({
        url: `${URL.apis}${id}/subscribe/`,
        method: 'POST',
      }),
      invalidatesTags: [TagTypesEnum.ACTIVE_API_LIST, TagTypesEnum.ONBOARDING_STATUS],
    }),
  }),
})
