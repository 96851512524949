import { keysApi } from './keys-api'
import { logsApi } from './logsapi'
import { marketPlaceAPI } from './marketplace'
import { projectApi } from './project'
import { teamApi } from './teamApi'
import { userApiSlice } from './user-api'
import { integratedAPI } from './integrated'
import { baseApiSlice } from './base-api'
import { PublisherApi } from './publisher-api'
import { subscriptionApi } from '.'

export * from './base-api'
export * from './integrated'
export * from './keys-api'
export * from './logsapi'
export * from './marketplace'
export * from './project'
export * from './teamApi'
export * from './user-api'
export * from './publisher-api'
export * from './subscription-api'

export const {
  useGetApiStatusQuery,
  useGetEarlyAccessMutation,
  useGetOnboardingQuestionsQuery,
  usePostOnboardingQuestionsMutation,
  useGetRoadmapQuery,
  usePostFeedbackMutation,
  useGetShareLinksQuery,
} = baseApiSlice

export const {
  useRegisterMutation,
  useUpdateUserMutation,
  useLoginMutation,
  useGetUserQuery,
  useLogoutMutation,
  useLoginWithGoogleMutation,
  useVerifyEmailMutation,
  useGetProfileQuery,
  useGetProfilePublishedApisQuery,
  useGetProfilePreferencesQuery,
  useResendVerifyEmailMutation,
  useUpdateProfileMutation,
  useUpdateUserProfileImageMutation,
} = userApiSlice
export const {
  useCreateProjectMutation,
  useGetProjectListQuery,
  useUpdateProjectSettingsMutation,
  useGetBillingInfoQuery,
  useRemoveProjectLogoMutation,
  useCheckDomainAvailabilityQuery,
  useProjectImageUpdateMutation,
  useAddOrganisationApiFieldMutation,
  useGetDashboardStatusQuery,
  useGetDashboardApiKeyStatusQuery,
  useGetDashboardApiAnalyticsQuery,
} = projectApi
export const {
  useGetProjectMembersQuery,
  useInviteMemberMutation,
  useRemoveMemberFromProjectMutation,
  useResendInviteMemberMutation,
  useAcceptProjectInviteMutation,
} = teamApi
export const {
  useGetApiBySlugQuery,
  useGetApiListQuery,
  useGetApiPerformanceMetricsQuery,
  useGetApiFaqQuery,
  useGetApiLatestFeedQuery,
  useGetApiPriceQuery,
  useGetApiSettingsQuery,
  useGetApiAboutInfoQuery,
  useGetApiEndpointsQuery,
  useGetMarketplaceQuery,
  useGetMarketPlaceSectionDataQuery,
  useGetApiReviewsQuery,
  useGetApiReviewDisplayDataQuery,
  useAddReviewMutation,
  useSubscribeApiMutation,
} = marketPlaceAPI
export const { useCreateApiKeysMutation, useGetApiKeysQuery, useDeleteApiKeyMutation, useGetApiKeyDetailQuery } =
  keysApi
export const { useGetApiLogsQuery, useGetApiLogDetailsQuery, useGetUsageQuotaQuery } = logsApi
export const { useGetApiInvoicesQuery, useGetApiSubscriptionQuery } = subscriptionApi
export const {
  useGetActiveApiDetailsQuery,
  useGetActiveApiListQuery,
  useAddApiToOrganizationMutation,
  useRemoveActiveApiMutation,
  useToggleActiveApiStatusMutation,
} = integratedAPI

export const {
  useAccessStatusQuery,
  useRequestAccessMutation,
  useGetPublishedApisQuery,
  useGetPublisherStatsQuery,
  useGetPublisherRevenueQuery,
  useGetPublisherRecentTransactionsQuery,
} = PublisherApi
