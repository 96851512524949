import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '@/lib/redux'

interface ApiInfo {
  orgApiId: number | null
  name: string
  docLink: string
  status: {
    title: string
    color: string
  }
  createdAt: string
}

interface CounterState {
  value: ApiInfo
}

const initialState: CounterState = {
  value: {
    orgApiId: null,
    name: '',
    docLink: '',
    status: {
      title: '',
      color: '',
    },
    createdAt: 'Now',
  },
}

export const orgApiSlice = createSlice({
  name: 'counter',

  initialState,
  reducers: {
    setOrgApiId: (state, action: PayloadAction<ApiInfo>) => {
      state.value = action.payload
    },
  },
})

export const { setOrgApiId } = orgApiSlice.actions
export const selectOrgApiId = (state: RootState) => state.orgApi.value
export const orgApiReducer = orgApiSlice.reducer
