/* eslint-disable @typescript-eslint/no-explicit-any */
import URL from '@/config/network'
import { TagTypesEnum, baseApiSlice } from './base-api'

export const teamApi = baseApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getProjectMembers: builder.query<any, string>({
      query: (id) => `${URL.project}${id}/members/`,
      providesTags: (result, error, id) => (result ? [{ type: TagTypesEnum.MEMBER, id }] : [TagTypesEnum.UNKNOWN]),
    }),
    inviteMember: builder.mutation<any, any>({
      query: ({ id, formData }) => ({
        url: `${URL.project}${id}/invite/`,
        body: { ...formData },
        method: 'POST',
      }),
      invalidatesTags: (result, error, { member_id: id }) => [{ type: TagTypesEnum.MEMBER, id }],
    }),
    resendInviteMember: builder.mutation<any, any>({
      query: ({ id, formData }) => ({
        url: `${URL.project}${id}/resend-invite/`,
        body: formData,
        method: 'POST',
      }),
    }),
    acceptProjectInvite: builder.mutation<any, any>({
      query: ({ id, formData }) => ({
        url: `${URL.project}${id}/accept-invite/`,
        body: formData,
        method: 'POST',
      }),
    }),
    removeMemberFromProject: builder.mutation<any, any>({
      query: ({ id, formData }) => ({
        url: `${URL.project}${id}/remove-member/`,
        body: { ...formData },
        method: 'POST',
      }),
      invalidatesTags: (result, error, { member_id: id }) => [{ type: TagTypesEnum.MEMBER, id }],
    }),
  }),
})
