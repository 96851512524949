const URL = {
  signup: 'auth/email/register/',
  login: 'auth/email/login/',
  loginWithGoogle: 'auth/google/',
  user: 'users/',
  onboarding: 'auth/onboarding/',
  logout: 'users/logout/',
  status: 'beacon/system-status/',
  project: 'orgs/',
  keys: 'keys/',
  logs: 'usage/',
  orgApi: 'org-api/',
  checkdomain: 'orgs/check-domain-availability/',
  earlyAccess: 'request/early-access/',
  apis: 'listing/',
  publisher: 'studio/',
  dashboard: 'dashboard/',
  roadmap: 'beacon/roadmap/',
  socialShareLinks: 'beacon/social/share/',
}

const baseUrl = {
  api:
    process.env.NEXT_PUBLIC_NODE_ENV === 'production' ? 'https://api.tinyapi.co/' : 'https://staging-api.tinyapi.co/',
}
// const baseUrl = {
//   api: 'https://api.tinyapi.co/',
// }

export { baseUrl }
export default URL
