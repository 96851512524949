import { IUsageLogQueryParams, IUsageLogResponse, LogDetails, UsageQuota } from '@/types/usage'
import { TagTypesEnum, baseApiSlice } from './base-api'
import URL from '@/config/network'

export const logsApi = baseApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getApiLogs: builder.query<IUsageLogResponse, IUsageLogQueryParams>({
      query: ({ org_api_id, status_code, keyword, date_from, date_to }) => {
        return {
          url: URL.logs,
          params: { org_api_id, status_code, keyword, date_from, date_to },
        }
      },
      providesTags: [TagTypesEnum.USAGELOGS],
    }),
    getApiLogDetails: builder.query<LogDetails, string>({
      query: (log_id) => {
        return {
          url: `${URL.logs}${log_id}`,
        }
      },
    }),
    getUsageQuota: builder.query<UsageQuota, number | null>({
      query: (id) => {
        return `${URL.project}api/${id}/usage`
      },
      providesTags: [TagTypesEnum.USAGELOGS],
    }),
  }),
})
