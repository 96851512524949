import URL from '@/config/network'
import { TagTypesEnum, baseApiSlice } from './base-api'
import { KeyDetails, KeyInput, Key, KeyResponse } from '@/types/keys'

export const keysApi = baseApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getApiKeys: builder.query<KeyResponse, void>({
      query: () => ({
        url: URL.keys,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.results.map(({ id }) => ({ type: TagTypesEnum.API_KEY, id })),
              { type: TagTypesEnum.API_KEY, id: 'API_KEY' },
            ]
          : [{ type: TagTypesEnum.API_KEY, id: 'API_KEY' }],
    }),
    createApiKeys: builder.mutation<Key, KeyInput>({
      query: ({ org_id, expires_in, key_name }) => ({
        url: URL.keys,
        body: { org_id, expires_in, key_name },
        method: 'POST',
      }),
      invalidatesTags: [{ type: TagTypesEnum.API_KEY, id: 'API_KEY' }],
    }),
    GetApiKeyDetail: builder.query<KeyDetails, string>({
      query: (slug) => ({
        url: `${URL.keys}details/`,
        params: { slug },
      }),
    }),
    deleteApiKey: builder.mutation<void, string>({
      query: (slug) => ({
        url: `${URL.keys}delete-key/`,
        params: { slug },
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: TagTypesEnum.API_KEY, id: 'API_KEY' }],
    }),
  }),
})
