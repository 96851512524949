import { createSlice } from '@reduxjs/toolkit'
import { RootState, userApiSlice } from '@/lib/redux'
import { IUser, IUserResponse } from '@/types/user'

type UserState = { user: IUser | null }
const initialState: UserState = {
  user: null,
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(
        userApiSlice.endpoints.getUser.matchFulfilled,
        (state: UserState, { payload }: { payload: IUserResponse }) => {
          state.user = payload.results[0]
        }
      )
      .addMatcher(userApiSlice.endpoints.logout.matchFulfilled, (state: UserState) => {
        state.user = null
      })
  },
})

export const userReducer = userSlice.reducer

export const selectUser = (state: RootState) => state.user.user
