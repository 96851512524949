'use client'

import { type ReactNode } from 'react'
import { Provider } from 'react-redux'
import { store } from '.'

interface StoreProviderProps {
  children: ReactNode
}

function StoreProvider(props: StoreProviderProps) {
  return <Provider store={store}>{props.children}</Provider>
}

export default StoreProvider
