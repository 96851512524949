/* eslint-disable @typescript-eslint/no-explicit-any */
import { ApiToOrganizationPayload, IActiveOrganisationApiListResponse, IApiDetails } from '@/types/api'
import { TagTypesEnum, baseApiSlice } from './base-api'
import URL from '@/config/network'

export const integratedAPI = baseApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addApiToOrganization: builder.mutation<true, ApiToOrganizationPayload>({
      query: (body) => ({
        url: `${URL.project}api/`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, _, { api_id: id }) => [{ type: TagTypesEnum.API_SERVICE, id }],
    }),
    getActiveApiList: builder.query<IActiveOrganisationApiListResponse, string | undefined>({
      query: (orgId) => `${URL.project}/api/?${orgId}`,
      providesTags: (_, __, id) => [{ type: TagTypesEnum.ACTIVE_API_LIST, id }],
    }),
    getActiveApiDetails: builder.query<IApiDetails, { orgId: number; orgApiId: number }>({
      query: ({ orgId, orgApiId }) => `${URL.project}/api/${orgApiId}/?org-id=${orgId}`,
      providesTags: (_, __, { orgApiId: id }) => [{ type: TagTypesEnum.ACTIVE_API_DETAILS, id }],
    }),
    toggleActiveApiStatus: builder.mutation<any, { id: number; active: 'True' | 'False' }>({
      query: ({ id, ...body }) => ({
        url: `${URL.project}${id}/`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: (_, __, { id }) => [{ type: TagTypesEnum.ACTIVE_API_DETAILS, id }],
    }),
    removeActiveApi: builder.mutation<any, { orgApiId: number; apiId: number; orgId: number }>({
      query: ({ orgApiId }) => ({
        url: `${URL.project} ${orgApiId}/`,
        method: 'DELETE',
      }),
      invalidatesTags: (_, __, { apiId, orgId }) => [
        { type: TagTypesEnum.API_SERVICE, id: apiId },
        { type: TagTypesEnum.ACTIVE_API_LIST, id: orgId },
      ],
    }),
  }),
})
