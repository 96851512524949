import URL, { baseUrl } from '@/config/network'
import { IApiStatus } from '@/types/api'
import { FeedbackInput } from '@/types/feedback'
import { IOnboardingResponse } from '@/types/onboarding'
import { RoadmapType } from '@/types/roadmap'
import { socialShareParams, socialShareResponse } from '@/types/socialShare'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

const baseQuery = fetchBaseQuery({
  baseUrl: baseUrl.api,
  prepareHeaders(headers) {
    return headers
  },
  credentials: 'include',
})

export enum TagTypesEnum {
  USER = 'USER',
  API_KEY = 'API_KEY',
  API_SERVICE = 'API_SERVICE',
  MEMBER = 'MEMBER',
  PROJECT = 'PROJECT',
  USAGELOGS = 'USAGELOGS',
  UNKNOWN = 'UNKNOWN',
  API_SETTINGS = 'API_SETTINGS',
  ACTIVE_API_DETAILS = 'ACTIVE_API_DETAILS',
  ACTIVE_API_LIST = 'ACTIVE_API_LIST',
  REVIEW = 'REVIEW',
  KEY_STATUS = 'KEY_STATUS',
  ANALYTICS = 'ANALYTICS',
  ONBOARDING_STATUS = 'ONBOARDING_STATUS',
  PUBLISHER_ACCESS = 'PUBLISHER_ACCESS',
  VERIFIED = 'VERIFIED',
  API_HEADER_DETAILS = 'API_HEADER_DETAILS',
}

export const baseApiSlice = createApi({
  reducerPath: 'api',
  baseQuery: baseQuery,
  tagTypes: Object.values(TagTypesEnum),
  endpoints: (builder) => ({
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getEarlyAccess: builder.mutation<any, any>({
      query: (body) => ({
        url: URL.earlyAccess,
        method: 'POST',
        body: body,
      }),
    }),
    getApiStatus: builder.query<IApiStatus, void>({
      query: () => URL.status,
    }),

    getOnboardingQuestions: builder.query<IOnboardingResponse[], void>({
      query: () => URL.onboarding,
    }),
    postFeedback: builder.mutation<void, FeedbackInput>({
      query: (body) => ({
        url: `beacon/feedback/`,
        method: 'POST',
        body,
      }),
    }),

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    postOnboardingQuestions: builder.mutation<any, any>({
      query: (body) => ({
        url: URL.onboarding,
        method: 'POST',
        body,
      }),
      invalidatesTags: [TagTypesEnum.USER],
    }),
    getRoadmap: builder.query<RoadmapType, void>({
      query: () => ({
        url: URL.roadmap,
      }),
    }),
    getShareLinks: builder.query<socialShareResponse, socialShareParams>({
      query: (params) => ({
        url: URL.socialShareLinks,
        params,
      }),
    }),
  }),
})
