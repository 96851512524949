import { InvoiceResponseType, SubscriptionResponseType } from '@/types/subscription'
import { baseApiSlice } from './base-api'
import URL from '@/config/network'

export const subscriptionApi = baseApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getApiSubscription: builder.query<SubscriptionResponseType, number | null>({
      query: (api_id) => {
        return {
          url: `${URL.project}/api/${api_id}/subscription-plan/`,
        }
      },
    }),
    getApiInvoices: builder.query<InvoiceResponseType, number | null>({
      query: (api_id) => {
        return {
          url: `${URL.project}/api/${api_id}/invoices/`,
        }
      },
    }),
  }),
})
