import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { RootState } from '../store'
import { OnBoardingSchema } from '@/types/onboarding'
// import { OnBoardingSchema } from '@/lib/schema/onboarding.schema'

const initialState: OnBoardingSchema = {
  response: [],
}

const onboardingSlice = createSlice({
  name: 'onboarding',
  initialState,
  reducers: {
    updateOnboarding: (state, action: PayloadAction<OnBoardingSchema>) => {
      return action.payload
    },
  },
})

export const { updateOnboarding } = onboardingSlice.actions
export const onboardingReducer = onboardingSlice.reducer
export const selectOnboarding = (state: RootState) => state.onboarding
