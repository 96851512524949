/* eslint-disable @typescript-eslint/no-explicit-any */
import URL from '@/config/network'
import { TagTypesEnum, baseApiSlice } from './base-api'
import { ILogin, IUserInput, IUserResponse, updateUserInput } from '@/types/user'
import {
  resendVerifyEmailParams,
  resendVerifyEmailResponse,
  verifyEmailInput,
  verifyEmailResponse,
} from '@/app/(auth)/_types/verifyEmail'
import { userProfile, userProfileInput } from '@/types/user-profile'
import { MarketplaceApi } from '@/types/api'
import { userPreferenceType } from '@/types/userPreferences'

export const userApiSlice = baseApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    register: builder.mutation<IUserResponse, IUserInput>({
      query: (body) => ({
        url: URL.signup,
        method: 'POST',
        body,
      }),
      invalidatesTags: (result) =>
        result ? [TagTypesEnum.USER, TagTypesEnum.API_HEADER_DETAILS] : [TagTypesEnum.UNKNOWN],
    }),
    login: builder.mutation<IUserResponse, ILogin>({
      query: (body) => ({
        url: URL.login,
        method: 'POST',
        body,
      }),
      invalidatesTags: (result) =>
        result ? [TagTypesEnum.USER, TagTypesEnum.API_HEADER_DETAILS] : [TagTypesEnum.UNKNOWN],
    }),
    loginWithGoogle: builder.mutation<IUserResponse, { token: string }>({
      query: (body) => ({
        url: URL.loginWithGoogle,
        method: 'POST',
        body,
      }),
      invalidatesTags: (result) =>
        result ? [TagTypesEnum.USER, TagTypesEnum.API_HEADER_DETAILS] : [TagTypesEnum.UNKNOWN],
    }),
    logout: builder.mutation<true, void>({
      query: () => ({
        url: URL.logout,
        method: 'POST',
      }),
      invalidatesTags: (result, error) => {
        return !error ? [TagTypesEnum.USER] : [TagTypesEnum.UNKNOWN]
      },
    }),
    getUser: builder.query<IUserResponse, void>({
      query: () => URL.user,
      providesTags: [TagTypesEnum.USER, TagTypesEnum.VERIFIED],
    }),
    updateUser: builder.mutation<IUserResponse, updateUserInput>({
      query: ({ id, ...rest }) => {
        return {
          url: `${URL.user}${id}/`,
          method: 'PATCH',
          body: rest,
        }
      },
      invalidatesTags: (result) =>
        result
          ? [
              TagTypesEnum.USER,
              TagTypesEnum.ACTIVE_API_LIST,
              TagTypesEnum.KEY_STATUS,
              TagTypesEnum.ANALYTICS,
              TagTypesEnum.ONBOARDING_STATUS,
              TagTypesEnum.USAGELOGS,
            ]
          : [TagTypesEnum.UNKNOWN],
    }),

    verifyEmail: builder.mutation<verifyEmailResponse, verifyEmailInput>({
      query: (body) => ({
        url: '/auth/verify-email/',
        method: 'POST',
        body,
      }),
      invalidatesTags: [TagTypesEnum.VERIFIED],
    }),
    resendVerifyEmail: builder.mutation<resendVerifyEmailResponse, resendVerifyEmailParams>({
      query: (body) => ({
        url: 'auth/email/resend-verification-email/',
        method: 'POST',
        body,
      }),
    }),

    getProfile: builder.query<userProfile, string | undefined>({
      query: (handle) => `users/profile/un/${handle}/`,
    }),
    getProfilePublishedApis: builder.query<MarketplaceApi[], string | undefined>({
      query: (handle) => `users/profile/un/${handle}/published-listing/`,
    }),
    getProfilePreferences: builder.query<userPreferenceType, string | undefined>({
      query: (id) => `${URL.user}profile/${id}/get-preferences/`,
    }),
    updateProfile: builder.mutation<userProfile, userProfileInput>({
      query: ({ id, ...body }) => ({
        url: `${URL.user}profile/${id}/`,
        method: 'PATCH',
        body,
      }),
    }),
    updateUserProfileImage: builder.mutation<any, any>({
      query: (body) => ({
        url: `${URL.user}${body.id}/update-profile-picture/`,
        method: 'POST',
        body: body.formdata,
        formData: true,
      }),
      invalidatesTags: [TagTypesEnum.USER],
    }),
  }),
})
