import { configureStore } from '@reduxjs/toolkit'
import { baseApiSlice, userReducer, onboardingReducer, orgApiReducer } from './slices'

export const store = configureStore({
  reducer: {
    user: userReducer,
    orgApi: orgApiReducer,
    onboarding: onboardingReducer,
    [baseApiSlice.reducerPath]: baseApiSlice.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(baseApiSlice.middleware),
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
