import {
  DashboardApiKeyAnalyticsType,
  DashboardApiKeyStatusType,
  DashboardStatusResponse,
  ICreateOrgData,
  IProjectResponse,
} from '@/types/project'
import { TagTypesEnum, baseApiSlice } from './base-api'
import URL from '@/config/network'
import { IApiBilling } from '@/types/api'

export const projectApi = baseApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getBillingInfo: builder.query<IApiBilling, string>({
      query: (org_id) => ({
        url: `${URL.project}${org_id}/billing-payments/`,
      }),
    }),

    checkDomainAvailability: builder.query<void, string>({
      query: (domain) => ({
        url: URL.checkdomain,
        params: {
          domain,
        },
      }),
    }),

    createProject: builder.mutation<IProjectResponse, ICreateOrgData>({
      query: (body) => ({
        url: URL.project,
        method: 'POST',
        body,
        formData: true,
      }),
      invalidatesTags: (result) => (result ? [TagTypesEnum.PROJECT, TagTypesEnum.USER] : [TagTypesEnum.UNKNOWN]),
    }),
    deleteProject: builder.mutation<IProjectResponse, ICreateOrgData>({
      query: (body) => ({
        url: URL.project,
        method: 'DELETE',
        body,
        formData: true,
      }),
      invalidatesTags: (result) => (result ? [TagTypesEnum.PROJECT, TagTypesEnum.USER] : [TagTypesEnum.UNKNOWN]),
    }),

    getProjectList: builder.query<IProjectResponse, void>({
      query: () => URL.project,
      providesTags: [TagTypesEnum.PROJECT],
    }),

    updateProjectSettings: builder.mutation<any, any>({
      query: ({ id, ...rest }) => ({
        url: `${URL.project}` + id + '/',
        method: 'PATCH',
        body: { ...rest },
      }),
      invalidatesTags: [TagTypesEnum.PROJECT],
    }),

    projectImageUpdate: builder.mutation<any, any>({
      query: (body) => ({
        url: `${URL.project}${body.id}/update-organisation-logo/`,
        method: 'POST',
        body: body.formdata,
        formData: true,
      }),
      invalidatesTags: [TagTypesEnum.PROJECT],
    }),

    removeProjectLogo: builder.mutation<any, any>({
      query: (body) => ({
        url: `${URL.project}${body.id}/`,
        method: 'PATCH',
        body: body.data,
      }),
      invalidatesTags: [TagTypesEnum.PROJECT],
    }),
    addOrganisationApiField: builder.mutation<any, { api_field: number; value: string; orgApiId: number }>({
      query: ({ ...body }) => ({
        url: '/org-api/14/update-fields/',
        method: 'POST',
        body,
      }),
      invalidatesTags: (_, __, { orgApiId: id }) => [{ type: TagTypesEnum.API_SETTINGS, id }],
    }),
    getDashboardStatus: builder.query<DashboardStatusResponse, void>({
      query: () => ({
        url: `${URL.dashboard}status/`,
      }),
      providesTags: [TagTypesEnum.ONBOARDING_STATUS],
    }),
    getDashboardApiKeyStatus: builder.query<DashboardApiKeyStatusType, void>({
      query: () => ({
        url: `${URL.dashboard}key-status/`,
      }),
      providesTags: [TagTypesEnum.KEY_STATUS],
    }),
    getDashboardApiAnalytics: builder.query<DashboardApiKeyAnalyticsType, void>({
      query: () => ({
        url: `${URL.dashboard}analytics/`,
      }),
      providesTags: [TagTypesEnum.ANALYTICS],
    }),
  }),
})
